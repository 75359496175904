<template>
  <header class="header col-12">
    <div class="header__caption row align-items-center">
      <div class="col-2">
        <router-link to="dashboard">
          <img :src="logo.url" :alt="logo.alt" />
        </router-link>
      </div>
      <div class="col-3">
        <CustomButton
          :label="$t('main.addHost')"
          icon="add"
          @click="hostModalShown = true"
        />
      </div>
      <YourManager class="col-4" :manager="$store.getters.manager || manager" />
      <div class="col"><UserPlate :username="$store.getters.login" /></div>
    </div>
    <NotificationsCarousel class="header__notifications-carousel" />
    <AddHostModal :shown="hostModalShown" @update:shown="val => hostModalShown = val" @submit-form="submitForm"/>
  </header>
</template>
<script>
import { Notification } from 'element-ui';

import CustomButton from '@/components/controls/CustomButton.vue';
import UserPlate from '@/components/controls/UserPlate.vue';
import YourManager from '@/components/controls/YourManager.vue';
import NotificationsCarousel from '@/components/controls/NotificationsCarousel.vue';
import AddHostModal from '@/components/modals/AddHostModal.vue';

export default {
  name: 'Header',
  components: {
    CustomButton,
    UserPlate,
    YourManager,
    NotificationsCarousel,
    AddHostModal,
  },
  data() {
    return {
      manager: {
        name: 'Александра Рахимова',
        email: 'alexandra.rakh@umedia.group',
      },
      modalShown: false,
    };
  },
  computed: {
    logo() {
      return {
        url: process.env.VUE_APP_LOGO_IMG,
        alt: process.env.VUE_APP_MODE_NAME,
      };
    },
    hostModalShown: {
      set(val) {
        this.$store.commit('hostModalShown', val);
      },
      get() {
        return this.$store.getters.hostModalShown;
      },
    },
  },
  created() {
    this.$store.dispatch('managerRequest');
    this.$store.dispatch('currencyRequest');
  },
  methods: {
    submitForm(payload) {
      const emptyFields = Object.keys(payload).filter(
        key => !payload[key],
      );
      if (emptyFields.length) {
        Notification.error({
          title: this.$t('notification.error'),
          message: this.$t('notification.errorRequiredFields'),
        });
        return;
      }

      if (payload.url) {
        const urlexpr = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
        if (!urlexpr.test(payload.url)) {
          Notification.error({
            title: this.$t('notification.error'),
            message: this.$t('notification.errorIncorrectUrl'),
          });
          return;
        }
      }

      this.$store.dispatch('addHost', payload).then(
        () => {
          Notification.success({
            title: this.$t('notification.success'),
            message: this.$t('notification.successAddHostX', [payload.url || '']),
          });
          this.$store.commit('hostModalShown', false);
        },
        () => {
          Notification.error({
            title: this.$t('notification.error'),
            message: this.$t('notification.errorAddHost'),
          });
        },
      );
    },
  },
};
</script>

<style lang="scss"></style>
