<template>
    <Modal :shown="shown" @update:shown="$emit('update:shown', $event)">
      <h2 slot="header" class="offset-3">{{ $t('main.addHost') }}</h2>
      <form slot="body" @submit.prevent="submitForm" novalidate>
        <template v-for="field in inputFields">
          <div v-if="field.visible" class="row py-3" :key="field.value">
            <div class="col-3 align-self-center form-label">
              {{ field.label }}
            </div>
            <div class="col-9">
              <input
                type="text"
                v-model="formData[field.value]"
                class="form-input"
                @focus="showErrors = true"
              />
              <div v-if="showErrors && !formData[field.value]" class="error-message">
                {{ $t('validations.required') }}
              </div>
            </div>
          </div>
        </template>
        <template v-for="select in selectFields">
          <div v-if="select.visible" class="row py-3" :key="select.value">
            <div class="col-3 align-self-center form-label">
              {{ select.label }}
            </div>
            <div class="col-9">
              <el-select
                style="width: 100%"
                v-model="formData[select.value]"
                :placeholder="select.placeholderKey ? $t(select.placeholderKey) : select.placeholderText"
                :disabled="select.value === 'environment' && isEnvironmentDisabled"
                @focus="showErrors = true"
              >
                <el-option
                  v-for="item in select.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <div v-if="showErrors && !formData[select.value]" class="error-message">
                {{ $t('validations.required') }}
              </div>
            </div>
          </div>
        </template>
        <div class="row py-3">
          <div class="offset-3 col-9">
            <div class="d-flex">
              <button type="submit" class="form-btn">
                {{ $t('common.add') }}
              </button>
            </div>
          </div>
        </div>
        <div class="row py-3">
          <div class="offset-3 col-9">
            <div class="d-flex flex-column">
              <span v-html="$t('common.contactUs')"></span>
              <div v-if="contacts.email">
                Email:
                <a :href="`mailto:${contacts.email}`"
                  >{{contacts.email}}</a
                >
              </div>
              <div v-if="contacts.telegramLink">
                Telegram:
                <a :href="contacts.telegramLink" target="_blank"
                  >{{ contacts.telegram }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </form>
      <div slot="footer" />
    </Modal>
  </template>
<script>
import Modal from '@/components/Modal.vue';

export default {
  name: 'AddHostModal',
  components: {
    Modal,
  },
  props: {
    shown: Boolean,
  },
  data() {
    return {
      showErrors: false,
      formData: {
        app_store_url: '',
        app_bundle: '',
        app_store_name: '',
        url: '',
        name: '',
        source_type: '',
        environment: '',
        placement: '',
        device_type: '',
      },
      sourceTypeOptions: [
        { label: `${this.$t('main.embedCode')} / VAST`, value: 'direct' },
        { label: 'Yandex Header Bidding', value: 'yandex_hb' },
      ],
      environmentOptions: [
        { label: this.$t('main.webSite'), value: 'web' },
        { label: this.$t('main.application'), value: 'app' },
      ],
      placementOptions: [
        { label: 'Instream video', value: 'instream' },
        { label: 'Outstream video', value: 'outstream' },
        { label: 'Banner', value: 'banner' },
      ],
      deviceTypeOptions: [
        { label: 'On', value: 'smart_tv' },
        { label: 'Off', value: 'auto' },
      ],
    };
  },
  computed: {
    contacts() {
      return {
        email: process.env.VUE_APP_CONTACT_EMAIL,
        telegram: process.env.VUE_APP_CONTACT_TELEGRAM,
        telegramLink: process.env.VUE_APP_CONTACT_TELEGRAM_LINK,
      };
    },
    isVastApp() {
      return this.formData.source_type === 'direct' && this.formData.environment === 'app';
    },
    currentSourceType() {
      return this.formData.source_type;
    },
    isEnvironmentDisabled() {
      return this.formData.source_type === 'yandex_hb';
    },
    inputFields() {
      return [
        {
          value: 'app_store_url',
          label: 'Store URL',
          visible: this.isVastApp,
        },
        {
          value: 'app_store_name',
          label: 'App name',
          visible: this.isVastApp,
        },
        {
          value: 'app_bundle',
          label: 'Bundle ID',
          visible: this.isVastApp,
        },
        {
          value: 'name',
          label: this.$t('main.hostName'),
          visible: !this.isVastApp,
        },
        {
          value: 'url',
          label: this.$t('main.hostUrl'),
          visible: !this.isVastApp,
        },
      ];
    },
    selectFields() {
      return [
        {
          value: 'source_type',
          label: this.$t('main.sourceType'),
          options: this.sourceTypeOptions,
          placeholderKey: 'main.sourceTypePlaceholder',
          visible: true,
        },
        {
          value: 'environment',
          label: this.$t('main.environment'),
          options: this.environmentOptions,
          placeholderKey: 'main.environmentPlaceholder',
          visible: true,
        },
        {
          value: 'placement',
          label: this.$t('main.placementType'),
          options: this.placementOptions,
          placeholderKey: 'main.placementTypePlaceholder',
          visible: true,
        },
        {
          value: 'device_type',
          label: 'Smart TV',
          options: this.deviceTypeOptions,
          placeholderText: 'On/Off',
          visible: this.isVastApp,
        },
      ];
    },
    currentFields() {
      return [
        ...this.inputFields.filter(field => field.visible),
        ...this.selectFields.filter(field => field.visible),
      ];
    },
  },
  methods: {
    submitForm() {
      const isValid = this.currentFields.every((field) => {
        if (!this.formData[field.value]) {
          return false;
        }
        return true;
      });

      if (!isValid) {
        this.showErrors = true;
        return;
      }

      const payload = this.currentFields.reduce((acc, field) => {
        acc[field.value] = this.formData[field.value];
        return acc;
      }, {});

      this.$emit('submit-form', payload);
    },
    resetForm() {
      this.formData = {
        app_store_url: '',
        app_bundle: '',
        app_store_name: '',
        url: '',
        name: '',
        source_type: '',
        environment: '',
        placement: '',
        device_type: '',
      };
      this.showErrors = false;
    },
  },
  watch: {
    shown(newVal) {
      if (!newVal) {
        this.resetForm();
      }
    },
    currentSourceType(newVal) {
      if (newVal === 'yandex_hb') {
        this.formData.environment = 'web';
      }
    },
  },
};
</script>

<style lang="scss">
  .error-message {
    color: red;
    font-size: 0.8em;
    margin-top: 4px;
  }
</style>
