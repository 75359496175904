import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import {
  Table, TableColumn, Notification, Loading, Dialog, Collapse, CollapseItem, Tooltip, Pagination, Select, Option,
} from 'element-ui';
import VCalendar from 'v-calendar';
import Vuelidate from 'vuelidate';
import VueYandexMetrika from 'vue-yandex-metrika';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import i18n from '@/i18n';
import Logger from '@/utils/logger';
import getUrlParams from '@/utils/getUrlParams';

import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import store from './store';

import 'flag-icon-css/sass/flag-icon.scss';
import 'vue-custom-scrollbar/dist/vueScrollbar.css';

import '@/styles/main.scss';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: `https://${process.env.VUE_APP_SENTRY}@sentry.utraff.info/5`,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    environment: process.env.VUE_APP_MODE_NAME,
  });
}

Vue.config.productionTip = false;

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

Vue.use(Table);
Vue.use(Dialog);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Tooltip);
Vue.use(TableColumn);
Vue.use(Loading.directive);
Vue.use(Pagination);
Vue.use(Select);
Vue.use(Option);

Vue.use(VCalendar);

Vue.use(Vuelidate);
Vue.use(Logger);

Vue.use(VueYandexMetrika, {
  id: process.env.VUE_APP_METRICA_ID,
  router,
  env: process.env.NODE_ENV,
});

const accessToken = getUrlParams(window.location.search).token;
if (accessToken) {
  localStorage.clear();
  localStorage.setItem('token', `Token ${accessToken}`);
  store.dispatch('authToken', { token: `Token ${accessToken}` }).then(() => {
    router.push('/');
  });
}

const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common.Authorization = token;
}

axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch('logoutRequest').then(() => {
        if (router.currentRoute.name !== 'login') {
          Notification.error({
            title: i18n.t('notification.errorAuth'),
            message: i18n.t('notification.errorAuthMessage'),
          });
          router.push('login');
        }
      });
    }
    return Promise.reject(error.response);
  },
);

Vue.prototype.$loading = Loading.service;

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');
